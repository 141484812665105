import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "./Insight.css";

function Insight() {
  return (
    <div className="insight">
      <Header />
      <div className="insight_body">
        <section className="insight_section_one">
          <div>
            <h3>My Online CV</h3>
            <h1>My career so far.</h1>
          </div>
        </section>
        <section className="insight_section_two">
          <div className="insight_section_two_div1">
            <h1>Work Experience.</h1>
          </div>
          <div className="insight_section_two_div2">
            <div>
              <h3>FULL STACK SOFTWARE ENGINEER</h3>
              <h1>
                <span style={{ color: "burlywood" }}>Geetek global,</span>{" "}
                London, United Kindom, January 2024 - Present
              </h1>
              <p>
                Build and design web applications. Maintain codebases. Design
                user interfaces.
              </p>
            </div>
            <div>
              <h3>MOBILE DEVELOPER</h3>
              <h1>
                <span style={{ color: "burlywood" }}>Whot Studios,</span> Abuja,
                Nigeria, July 2024 - Present
              </h1>
              <p>
                Build and develop mobile applications for native android and ios
                or cross platform.
              </p>
            </div>
            <div>
              <h3>MOBILE DEVELOPER</h3>
              <h1>
                <span style={{ color: "burlywood" }}>Medis Mart,</span> Lagos,
                Nigeria, March 2024 - May 2024
              </h1>
              <p>
                Developed mobile applications for HMOs accross Nigeria using
                native and cross platform technologies.
              </p>
            </div>
            <div>
              <h3>GRAPHICS AND BRAND DESIGNER</h3>
              <h1>
                <span style={{ color: "burlywood" }}>Sakne Designs,</span>{" "}
                Remote, November 2023 - Present
              </h1>
              <p>
                Produce quality product and brand designs for clients. Design
                flyers, posters, banners and any required graphics design
              </p>
            </div>
            <div>
              <h3>NYSC ENGINNERING TRAINEE</h3>
              <h1>
                <span style={{ color: "burlywood" }}>
                  Chibufavor Automobile,
                </span>{" "}
                Umuahia, Abia, August 2023 - July 2024
              </h1>
              <p>
                Maintenance of various types of vehicles. Repairs and
                Diagnostics of vehicles.
              </p>
            </div>
            <div>
              <h3>LEAD DEVELOPER AND UI/UX DESIGNER</h3>
              <h1>
                <span style={{ color: "burlywood" }}>Project group,</span>{" "}
                Umuahia, Abia, August 2023 - Present
              </h1>
              <p>
                Led the frontend and backend engineering team. Design user
                interfaces.{" "}
              </p>
            </div>
            <div>
              <h3>CHIEF EDITOR</h3>
              <h1>
                <span style={{ color: "burlywood" }}>
                  Mechanical engineering journal,
                </span>{" "}
                Universiy of Benin, Benin, August 2023 - Present
              </h1>
              <p>
                Reviewed articles, contributed to editorial pieces, edited
                proffered articles, managed activities, roles and personnel.
              </p>
            </div>
            <div>
              <h3>LEAD DEVELOPER</h3>
              <h1>
                <span style={{ color: "burlywood" }}>
                  Mileage Africa Company Limited,
                </span>{" "}
                Lekki, Lagos, May 2022 - December 2022
              </h1>
              <p>
                Led the IT department, hands on frontend and backend
                engineering, improvement on the existing codebase and management
                of personnel.
              </p>
            </div>
            <div>
              <h3>FRONTEND ENGINEER</h3>
              <h1>
                <span style={{ color: "burlywood" }}>
                  Mothership Africa Company Limited,
                </span>{" "}
                Remote, January 2022 - April 2022
              </h1>
              <p>
                Developed the frontend web app and landing page using react
                framework.
              </p>
            </div>
            <div>
              <h3>SIWES Internship</h3>
              <h1>
                <span style={{ color: "burlywood" }}>NNPC - Cheveron JV</span>{" "}
                Umuahia, Abia, April 2021 - July 2021
              </h1>
              <p>
                Well head management, Drilling operations, flow station
                operations, administrative duties and seminar presentations.
              </p>
            </div>
            <div>
              <h3>HUMAN RESOURCE PERSONNEL</h3>
              <h1>
                <span style={{ color: "burlywood" }}>Capital X Ventures,</span>{" "}
                Warri, Delta State, October 2017 - December 2017
              </h1>
              <p>
                Intern at Human resource team, management of day to day
                activities.
              </p>
            </div>
            <div>
              <h3>UBItS INtERNSHIP</h3>
              <h1>
                <span style={{ color: "burlywood" }}>
                  Faculty of Engineering, University of Benin,
                </span>{" "}
                Benin City, Edo State, November 2016 - December 2016
              </h1>
              <p>Engineering maintenance and workshop practices.</p>
            </div>
          </div>
        </section>
        <section className="insight_section_education">
          <div className="insight_section_education_div1">
            <h1>Education.</h1>
          </div>
          <div className="insight_section_education_div2">
            <div>
              <h3>
                MASTERS OF SCIENCE IN MECHANICAL ENGINEERING (THERMOFLUID).
              </h3>
              <h1>
                <span>University of Lagos,</span> Lagos State
              </h1>
              <p>2025</p>
            </div>
            <div>
              <h3>BACHELOR IN ENGINEERING, MECHANICAL ENGINEERING.</h3>
              <h1>
                <span>University of Benin,</span> Benin City, Edo State
              </h1>
              <p>December 2022</p>
            </div>
            <div>
              <h3>HIGH SCHOOL DIPLOMA</h3>
              <h1>
                <span>School of secondary educaion, I.C.E.</span> Warri, Delta
                state
              </h1>
              <p> July 2013</p>
            </div>
          </div>
        </section>
        <section className="insight_section_skills">
          <div className="insight_section_skills_div1">
            <h1>Skills.</h1>
          </div>
          <div className="insight_section_skills_div2">
            <div>
              <h3>SOFTWARE ENGINEERING</h3>
              <p>
                Proficient in HTML, CSS, Javascript, Typescript, React, React
                Native, Bootstrap, Jquery, NodeJS, ExpressJS, SQL, PostgresQL,
                Knex, Python, Machine learning and C++.
              </p>
            </div>
            <div>
              <h3>MICROSOFT PACKAGES</h3>
              <p>
                Proficient in Microsoft Office, MSWord, Excel and PowerPoint.
              </p>
            </div>
            <div>
              <h3>MECHANICAL ENGINEERING</h3>
              <p>
                Expert level in AutoCAD, Ansys, Matlab and Solidworks.
                Intermediary level in Autodesk Inventor and Ansys. Proficient in
                Machine learning using python programming language.
              </p>
            </div>
            <div>
              <h3>GENERAL SKILLS</h3>
              <p>
                Advanced communications skill, research and development, team
                management, project supervision and scheduling of meetings,
                strategic planning, literature writing and content management.
              </p>
            </div>
          </div>
        </section>
        <section className="insight_section_education">
          <div className="insight_section_education_div1">
            <h1>Lincences and Cerification.</h1>
          </div>
          <div className="insight_section_education_div2">
            <div>
              <h3>CERTIFIED OIL AND GAS SAFETY PROFESSIONAL.</h3>
              <h1>ISO QMS</h1>
              <p>issued: June 2023</p>
            </div>
            <div>
              <h3>CERTIFIED PROJECT MANAGEMENT PROFESSIONAL.</h3>
              <h1>Gold Seal Certification IGO</h1>
              <p>issued: October 2023</p>
            </div>
            <div>
              <h3>
                WSO OCCUPATIONAL AND ENVIROMENTAL SAFETY AND HEALTH (HSE LEVEL
                1,2 AND 3) CERTIFICATIONS.
              </h3>
              <h1>World Safety Organization</h1>
              <p>issued: October 2023</p>
            </div>
            <div>
              <h3>HUMAN RESOURCE MANAGEMENT CERTIFICATION</h3>
              <h1>British Certification Inc.</h1>
              <p>issued: October 2023</p>
            </div>
            <div>
              <h3>ENVIROMENTAL IMPACT ASSESMENT</h3>
              <h1>
                Enviromental Impact Asssesment Experts' Certification Scheme.
              </h1>
              <p>issued: October 2023</p>
            </div>
            <div>
              <h3>BASIC FINANCIAL EDUCATION CERTIFICATE</h3>
              <h1>Kudimata, NNPC</h1>
              <p>issued: August 2023</p>
            </div>
          </div>
        </section>
        <section className="insight_section_two">
          <div className="insight_section_two_div1">
            <h1>Membership and affiliations.</h1>
          </div>
          <div className="insight_section_two_div2">
            <div>
              <h3>CORPERATE MEMBER.</h3>
              <h1>
                Nigerian Institution of Professional Engineers and Scientists
                (NIPES)
              </h1>
            </div>
            <div>
              <h3>CORPERATE MEMBER.</h3>
              <h1>Nigerian Society of Engineers (NSE)</h1>
            </div>
            <div>
              <h3>MEMBER.</h3>
              <h1>International Association of Engineers (IAENG)</h1>
            </div>
            <div>
              <h3>MEMBER.</h3>
              <h1>Institute of Mechanical Engineers (IMechE)</h1>
            </div>
            <div>
              <h3>AFFILIATE MEMBER.</h3>
              <h1>Nigerian Institute of Mechanical Engineers (NIMechE)</h1>
            </div>
          </div>
        </section>
        <section className="insight_section_education">
          <div className="insight_section_education_div1">
            <h1>Publications and research.</h1>
          </div>
          <div className="insight_section_education_div2">
            <div>
              <h3>
                DESIGN, FABRICATION AND PERFORMANCE EVALUATION OF WASTE PLASTICE
                SHREDDER.
              </h3>
              <h1>
                Department of mechanical engineering, faculty of engineering,
                University of Benin
              </h1>
            </div>
            <div>
              <h3>DESIGN AND FABRICATION OF A MINI FIRE FIGHTER ROBOT.</h3>
              <h1>NIPES Engineering journal | Research Gate</h1>
            </div>
          </div>
        </section>
        <section className="insight_section_education">
          <div className="insight_section_education_div1">
            <h1>Voluneering.</h1>
          </div>
          <div className="insight_section_education_div2">
            <div>
              <h3>VICE PRESIDENT.</h3>
              <h1>Giant Stars Fellowship, Benin City</h1>
              <p>2021 - 2022</p>
            </div>
            <div>
              <h3>ENGINEERING TUTOR</h3>
              <h1>NIMechE, University of Benin</h1>
              <p>2019 - 2021</p>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Insight;
